<div [class]="dialogClass + ' dialog'">
  <button
    *ngIf="data.showCloseButton"
    class="dialog-close-btn"
    mat-icon-button
    tabindex="-1"
    [matDialogClose]="'close'"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div
    class="dialog-title d-flex flex-column align-items-center mb-0"
    matDialogTitle
  >
    <img
      *ngIf="data.imageSrc"
      class="mb-4"
      [src]="data.imageSrc"
      [style.width.px]="imageWidth"
      [style.height.px]="imageHeight"
      alt="alert modal image"
    />
    <h2
      class="mb-2 text-center"
      mat-dialog-title
    >
      {{ data.title | translate }}
    </h2>
  </div>

  <p class="dialog-content text-center m-0">
    <ng-container *ngIf="!!data?.message?.length">{{ data.message! | translate: data.messageOptions }}</ng-container>
    <ng-container *ngIf="data?.messageArray?.length">
      <p
        *ngFor="let msgItem of data.messageArray"
        class="text-left"
      >
        {{ msgItem | translate }}
      </p>
    </ng-container>
  </p>

  <div
    *ngIf="data.contentImage"
    [ngClass]="data.contentImage.containerClasses || ''"
  >
    <img
      [src]="data.contentImage.src"
      [style.height.px]="data.contentImage.height"
      [style.width.px]="data.contentImage.width"
      [ngClass]="data.contentImage.imageClasses || ''"
    />
  </div>

  <ng-container *ngIf="data.radioGroup">
    <mat-radio-group
      [formControl]="control"
      aria-labelledby="access-group-label"
      class="d-flex flex-column mb-2"
    >
      <div
        *ngFor="let button of data.radioGroup.buttons"
        class="radio-group-button"
        [ngClass]="{ 'radio-group-button-disabled': button.disabled }"
      >
        <mat-radio-button
          [value]="button.value"
          [disableRipple]="true"
          [disabled]="button.disabled"
          class="fs-14 fw-medium"
        >
          {{ button.title | translate }}
        </mat-radio-button>
        <div
          *ngIf="button.hintText"
          class="radio-group-button-hint fs-12 fw-regular"
        >
          {{ button.hintText | translate }}
        </div>
      </div>
    </mat-radio-group>
  </ng-container>

  <div class="dialog-actions justify-content-center mt-2 d-flex flex-column">
    <div
      *ngFor="let group of data.actions"
      class="d-flex mt-2"
    >
      <button
        *ngFor="let action of group"
        class="btn btn--small"
        [ngClass]="action.buttonClass || ''"
        (click)="onClickAction(action)"
      >
        {{ action.buttonText | translate }}
      </button>
    </div>
  </div>
</div>
