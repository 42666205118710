import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';

import { IDialogItem } from './interfaces/dialog-item';

import { Throw } from '@shared/utils/throw';
import { SubmenuItem } from '@shared/modules/components/app-selectable-submenu';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ButtonToggle } from '@shared/modules/components/app-button-toggle-group/models/buttons-toggle.interface';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrls: ['./app-dialog.component.scss'],
})
export class AppDialogComponent implements OnInit {
  @Input() public dialogWidthClass: 'small' | 'medium' | 'medium-fixed' | 'large' | 'large-fixed' = 'small';
  @Input() public mode: 'default' | 'toggle' = 'default';
  @Input() public showPrevBtn = false;
  @Input() public prevBtnLabel = '';
  @Input() public items: IDialogItem[] = [];
  @Input() public loading = false;
  @Input() public customDialogMargin = 'mt-3';

  private destroyRef = inject(DestroyRef);

  public selectableMenuItem?: SubmenuItem;
  public submenuItems: SubmenuItem[] = [];
  public selectedItem?: IDialogItem;
  public allowedItems: IDialogItem[] = [];
  public buttonToggleGroup: ButtonToggle[] = [];
  public buttonToggleFormControl = new UntypedFormControl('');

  public get isOneItem(): boolean {
    return this.allowedItems.length === 1;
  }

  @Output() public readonly closeEvent: EventEmitter<'close' | 'back' | 'esc'> = new EventEmitter<
    'close' | 'back' | 'esc'
  >();

  @Output() public readonly selectItem: EventEmitter<IDialogItem> = new EventEmitter<IDialogItem>();

  private dialogItemToMenu(dialogItem: IDialogItem): SubmenuItem {
    return { ...dialogItem, hasPermission: true };
  }

  constructor(private dialogRef: MatDialogRef<any>) {}

  public ngOnInit(): void {
    Throw.if(this.items.length === 0, 'At least one item is required');

    this.allowedItems = this.items.filter((item) => item.hasPermission !== false);
    this.selectedItem = this.allowedItems[0];

    if (!this.isOneItem && this.mode === 'toggle') {
      this.buttonToggleGroup = this.allowedItems.map((item) => {
        return {
          name: item.toggleTitle || item.title,
          value: item.id!,
        };
      });
    }

    if (!this.isOneItem) {
      this.submenuItems = this.allowedItems.map((item) => this.dialogItemToMenu(item));
      this.selectableMenuItem = this.submenuItems[0];
    }

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.closeEvent.emit('esc');
      }
    });

    this.buttonToggleFormControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((data) => {
          this.onSelectedItem(this.allowedItems.find((i) => i.id === data)!);
        })
      )
      .subscribe();
  }

  public clickPrev(): void {
    this.closeEvent.emit('back');
  }

  public closeDialog(): void {
    this.closeEvent.emit('close');
  }

  public onSelectedItem(item: SubmenuItem): void {
    const selectableItem = this.allowedItems.find((i) => i.id === item.id);
    if (selectableItem) {
      this.selectedItem = selectableItem;
      this.selectableMenuItem = this.dialogItemToMenu(selectableItem);
      this.selectItem.emit(this.selectedItem);
    }
  }
}
