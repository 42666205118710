import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { AppReturnPreviousModalComponent } from './app-return-previous-modal.component';

@NgModule({
  declarations: [AppReturnPreviousModalComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslateModule],
  exports: [AppReturnPreviousModalComponent],
})
export class AppReturnPreviousModalModule {}
