import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ImageHelperModule } from 'src/app/shared/pipes/auth-image/image-helper.module';

import { AppUserPhotoModule } from '../app-user-photo/app-user-photo.module';

import { AppMenuListItemComponent } from './components/app-menu-list-item/app-menu-list-item.component';
import { AppStudiositeNavComponent } from './components/app-studiosite-nav/app-studiosite-nav.component';
import { AppSidenavComponent } from './components/app-sidenav/app-sidenav.component';
import { ActionGroupModalModule } from '@shared/modules/modals/action-group-modal';

@NgModule({
  declarations: [AppSidenavComponent, AppMenuListItemComponent, AppStudiositeNavComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    AppUserPhotoModule,
    ImageHelperModule,
    ActionGroupModalModule,
  ],
  exports: [AppSidenavComponent],
})
export class AppSidenavModule {}
