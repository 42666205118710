import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';

import { ActionGroupModalComponent } from './action-group-modal.component';
import { ActionGroupModalService } from './action-group-modal.service';

import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ActionGroupModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [ActionGroupModalService],
})
export class ActionGroupModalModule {}
