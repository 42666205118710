export enum WarningCodeEnum {
  CanNotRestrictBookings = 5001,
  BFTTCanNotBeConfirmed = 5002,
  CanNotRestrictBookingsAR = 5003,
  HasConflictsWithInvoice = 5004,
  HasConflictsWithSessionSheet = 5005,
  HasConflictsWithHoldLineInvoice = 5006,
  CanNotSendToSapWithHoldEvents = 5007,
  NonBookingRestrictsMeeting = 5011,
  EventCanNotRestrictMeetings = 5012,
  showConfirmationModal = 5013,
  ContinueUsePRS = 5014,
}
