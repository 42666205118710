import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export interface ToasterComponentData {
  type: ToastType;
  title: string;
  content: { message?: string; description?: string };
  link?: string;
  component?: any;
  componentInputs?: any;
}

@Component({
  selector: 'app-toaster',
  templateUrl: './app-toaster.component.html',
  styleUrls: ['./app-toaster.component.scss'],
})
export class AppToasterComponent {
  toastType = ToastType;
  constructor(
    private snackBarRef: MatSnackBarRef<AppToasterComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ToasterComponentData
  ) {}

  closeToast() {
    this.snackBarRef.dismiss();
  }
}
