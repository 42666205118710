import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';

import {
  AppToasterComponent,
  ToasterComponentData,
  ToastType,
} from '../modules/components/app-toaster/app-toaster.component';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private get sharedConfig() {
    return {
      direction: 'ltr',
      horizontalPosition: 'right',
      verticalPosition: 'top',
    } as MatSnackBarConfig;
  }

  constructor(private snackBar: MatSnackBar) {}

  public success(text = '') {
    return this.show(ToastType.SUCCESS, text);
  }

  public error(text = '') {
    return this.show(ToastType.ERROR, text);
  }

  public warning(text = '') {
    return this.show(ToastType.WARNING, text);
  }

  public info(text = '') {
    return this.show(ToastType.INFO, text);
  }

  /***
   * Display a stylized toast with some content in it. Makes the entire toast clickable
   * @param type - The type of the toast which determines the background color of the title
   * @param title - The text of the title
   * @param message - The first row of the content (bolder and bigger text)
   * @param description - The second row of the content (smaller text)
   * @param link - An absolute href (either to V1 or V2 route).
   */
  public showWithContent(type: ToastType, title = '', message = '', description = '', link = '') {
    // TODO: setTimeout temporary fix, we must remove the other setTimeout
    setTimeout(() => {
      return this.snackBar.openFromComponent(AppToasterComponent, {
        ...this.sharedConfig,
        duration: 6000,
        panelClass: 'sc-toast',
        data: {
          type,
          title,
          content: { message, description },
          link,
        } as ToasterComponentData,
      });
    });
  }

  /***
   * Display a stylized toast with a component as its content. Makes the entire toast clickable
   * @param type - The type of the toast which determines the background color of the title
   * @param title - The text of the title
   * @param component - A component injection token. For example: AppComponent
   * @param componentInputs - An object containing the inputs for the component. For example: {patientCode: 1423}
   */
  public showWithComponent(type: ToastType, title = '', component: any, componentInputs = {}) {
    // TODO: setTimeout temporary fix, we must remove the other setTimeout
    setTimeout(() => {
      return this.snackBar.openFromComponent(AppToasterComponent, {
        ...this.sharedConfig,
        duration: 6000,
        panelClass: 'sc-toast',
        data: {
          type,
          title,
          component,
          componentInputs,
        } as ToasterComponentData,
      });
    });
  }

  private show(type: ToastType, title = '') {
    // TODO: setTimeout temporary fix, we must remove the other setTimeout
    setTimeout(() => {
      return this.snackBar.openFromComponent(AppToasterComponent, {
        ...this.sharedConfig,
        duration: 4000,
        panelClass: 'sc-toast',
        data: {
          type,
          title,
        } as ToasterComponentData,
      });
    });
  }
}
