import { Injectable } from '@angular/core';

import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ActionGroupModalComponent } from './action-group-modal.component';
import { IActionGroupModal } from './models/action-group-modal.interface';

@Injectable()
export class ActionGroupModalService {
  constructor(private dialog: MatDialog) {}
  public open<T = unknown>(data: IActionGroupModal<T>): MatDialogRef<ActionGroupModalComponent<T>> {
    return this.dialog.open<ActionGroupModalComponent<T>>(ActionGroupModalComponent, {
      data,
      panelClass: 'action-group-modal-panel',
      disableClose: data.disableClose,
    });
  }

  public close(): void {
    this.dialog.closeAll();
  }
}
