import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NoAuthGuard } from '@auth/services/no-auth.guard';
import { AuthGuard } from '@auth/services/auth.guard';
import { AuthService } from '@auth/services/auth.service';
import { AppToasterModule } from '@shared/modules/components/app-toaster/app-toaster.module';
import { UserPermissionGuard } from '@auth/services/user-permission.guard';
import { AppSidenavModule } from '@shared/modules/components/app-sidenav/app-sidenav.module';
import { AppInputModule } from '@shared/modules/components/app-input/app-input.module';
import { AppButtonModule } from '@shared/modules/components/app-button/app-button.module';

import { CoreEffects } from './store/core.effects';
import { reducer } from './store/core.reducers';
import { NgxInactivityDirective } from './directives/ngx-inactivity.directive';
import { AppComponent } from './containers/app/app.component';

import { AppContentContainerComponent } from './containers/app-content-container/app-content-container.component';
import { HomePermissionGuard } from '@auth/services/home-permission.guard';
import { MiniVersionGuard } from '@auth/services/mini-version.guard';
import { InvoicingNotRequiredGuard } from '@auth/services/invoicing-not-required.guard';
import { MaterialIconsModule } from '@core/material-icons';
import { ThirdPartyStudioGuard } from '@core/third-party-studio';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/locale/', `.json?t=${+new Date()}`);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: false,
    }),

    StoreModule.forFeature('core', reducer),
    EffectsModule.forFeature([CoreEffects]),
    ReactiveFormsModule,
    AppSidenavModule,
    AppInputModule,
    AppButtonModule,
    AppToasterModule,
    MatSnackBarModule,
    MaterialIconsModule,
    MatLegacyProgressSpinnerModule,
  ],
  declarations: [AppComponent, NgxInactivityDirective, AppContentContainerComponent],
  exports: [AppComponent],
  providers: [
    AuthService,
    AuthGuard,
    NoAuthGuard,
    UserPermissionGuard,
    HomePermissionGuard,
    MiniVersionGuard,
    InvoicingNotRequiredGuard,
    ThirdPartyStudioGuard,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
