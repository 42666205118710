import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';

import { IActionGroupModal } from './models/action-group-modal.interface';
import { IActionModal } from '@shared/modules/modals/action-group-modal/models/action-modal.interface';

@Component({
  selector: 'app-action-group-modal',
  templateUrl: './action-group-modal.component.html',
  styleUrls: ['./action-group-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionGroupModalComponent<T = unknown> implements OnInit {
  public readonly control: UntypedFormControl = new UntypedFormControl();
  public readonly dialogClass;
  public readonly imageWidth: number;
  public readonly imageHeight: number;

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public readonly data: IActionGroupModal<T>
  ) {
    this.dialogClass = data?.dialogClassName ?? '';
    this.imageHeight = data?.imageHeight ?? 64;
    this.imageWidth = data?.imageWidth ?? 64;
  }

  public ngOnInit(): void {
    this.control.setValue(this.data.radioGroup?.initialValue);
  }

  public onClickAction(action: IActionModal<T>): void {
    if (action.onClick) {
      action.onClick(this.control.value);
    }
    this.dialogRef.close();
  }
}
