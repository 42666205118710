<div class="sidenav-shadow w-100 d-flex vh-100">
  <app-studiosite-nav [darkMode]="darkMode"></app-studiosite-nav>
  <div
    class="sidenav-menu vh-100 d-flex flex-column"
    [ngClass]="{
      'dark-mode': darkMode,
      'light-mode': !darkMode,
      closed: !isOpen
    }"
  >
    <button
      mat-icon-button
      class="d-flex align-items-center position-absolute toggle-menu-button ignore-grid-outside-click"
      [ngClass]="darkMode ? 'dark-mode' : 'light-mode'"
      *ngIf="!isOpen"
      (click)="openMenu()"
    >
      <mat-icon class="menu-mat-icon">navigate_next</mat-icon>
    </button>

    <button
      mat-icon-button
      class="d-flex align-items-center position-absolute toggle-menu-button ignore-grid-outside-click"
      [ngClass]="darkMode ? 'dark-mode' : 'light-mode'"
      *ngIf="isOpen"
      (click)="closeMenu()"
    >
      <mat-icon class="menu-mat-icon">chevron_left</mat-icon>
    </button>
    <ng-container *ngIf="user">
      <div
        *ngIf="(activeStudiosite$ | async)?.miniVersion"
        class="mini-version-bar bg-primary fs-11 lh-13 fw-medium d-flex align-items-center justify-content-center"
        [class.short-bar]="!isOpen"
      >
        <label
          *ngIf="isOpen"
          class="color-white"
          >{{ "SIDENAV.MINI_TITLE_STUDIO" | translate }}</label
        >
        <label
          *ngIf="isOpen"
          class="color-white opacity-60"
          >{{ "SIDENAV.MINI_TITLE_CONSOLE" | translate }}</label
        >
        <label class="color-white">{{ "SIDENAV.MINI_TITLE_MINI" | translate }}</label>
      </div>
      <div
        *ngIf="(activeStudiosite$ | async)?.miniVersion"
        class="mini-version-bg"
        [class.short-bar]="!isOpen"
      ></div>

      <div
        *ngIf="isOpen"
        class="channel-title d-flex align-items-start flex-column"
        [class.mt-4]="(activeStudiosite$ | async)?.miniVersion"
      >
        <ng-container *ngIf="isOnHomePage">
          <h2
            class="mb-0 fs-20 fw-black"
            [class.white-title]="darkMode"
          >
            {{ user.fullName }}
          </h2>
          <span class="fs-14 color-secondary">
            {{ user.defaultRole }}{{ !!user.company && !!user.defaultRole ? ", " : null }}{{ user.company }}
          </span>
        </ng-container>
        <ng-container *ngIf="!isOnHomePage">
          <h2
            class="mb-0 fs-20 fw-black"
            [class.white-title]="darkMode"
          >
            {{ (activeStudiosite$ | async)?.studioSiteName }}
          </h2>
          <span class="fs-14 color-secondary">
            {{ getUserNameAndRole(user) }}
          </span>
        </ng-container>
      </div>

      <mat-list
        class="sidenav-menu-links d-flex flex-column py-0 pr-3 h-100"
        [class.open]="isOpen"
      >
        <ng-container *ngFor="let item of fixedNavItems; let first = first">
          <app-menu-list-item
            *ngIf="isShow(item, (activeStudiosite$ | async)?.studioSiteId)"
            [item]="item"
            [user]="user"
            [first]="first"
            [isOpen]="isOpen"
            [darkMode]="darkMode"
            [isAr]="isArUser && !isOnHomePage"
          ></app-menu-list-item>
        </ng-container>
      </mat-list>

      <div
        class="d-flex download-app pointer"
        [ngClass]="{
          'download-app-width': isOpen,
          'width-fit-content': !isOpen,
          'download-app-dark-mode': darkMode
        }"
        (click)="openDownloadAppModal()"
      >
        <img
          class="icon-size-32 download-app-icon"
          src="./assets/icons/download-app-icon.png"
          alt="Download app icon"
        />
        <div
          *ngIf="isOpen"
          class="download-app-info lh-14"
        >
          <div class="fs-11 fw-bold">{{ "SIDENAV.DOWNLOAD_APP_TITLE" | translate }}</div>
          <div class="fs-10">{{ "SIDENAV.DOWNLOAD_APP_MESSAGE" | translate }}</div>
        </div>
      </div>

      <div
        class="d-flex justify-content-between user-block w-100"
        [ngClass]="{
          'flex-column': !isOpen,
          'align-items-center': isOpen
        }"
      >
        <app-user-photo
          class="user-photo overflow-hidden"
          [user]="user"
        ></app-user-photo>

        <div
          *ngIf="isOpen"
          class="ml-2"
        >
          <div
            class="fw-semi-bold fs-16 text-overflow-ellipsis"
            [class.white-title]="darkMode"
            [matMenuTriggerFor]="menu"
          >
            {{ user.name }}
          </div>
          <div
            class="fs-11 color-secondary text-overflow-ellipsis"
            [matTooltip]="user.email || ''"
          >
            {{ user.email || "" }}
          </div>
        </div>

        <button
          *ngIf="isOpen"
          mat-icon-button
          [class.white-title]="darkMode"
          class="logout-btn"
          (click)="onLogout()"
        >
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu
  #menu="matMenu"
  class="p-2"
>
  <div>
    <b>v{{ version }} ({{ versionBuild }})</b>
  </div>
  <span>{{ "SHARED_MODULE.SESSION_STARTED" | translate }} {{ tokenExpire | date: "medium" }}</span>
</mat-menu>
