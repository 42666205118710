import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { AppButtonToggleGroupComponent } from './app-button-toggle-group.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AppButtonToggleGroupComponent],
  imports: [CommonModule, MatTooltipModule, MatButtonToggleModule, MatButtonModule, MatIconModule, ReactiveFormsModule],
  exports: [AppButtonToggleGroupComponent],
})
export class AppButtonToggleGroupModule {}
