import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { AutoCompleteSearchPipe } from '@shared/pipes/auto-complete-search.pipe';

import { TranslateModule } from '@ngx-translate/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import { AppInputModule } from '@shared/modules/components/app-input/app-input.module';

import { AppButtonModule } from '@shared/modules/components/app-button/app-button.module';

import { AppDialogModule } from '@shared/modules/modals/app-dialog/app-dialog.module';

import { LoginComponent } from './components/login/login.component';
import { LoginProcessorComponent } from './components/login-processor/login-processor.component';
import { AuthEffects } from './store/auth.effects';
import { authReducer } from './store/auth.reducer';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthPageLayoutComponent } from './components/auth-page-layout/auth-page-layout.component';
import { Auth2PageLayoutComponent } from './components/auth-page-layout/auth2-page-layout.component';

import { OktaSessionService } from './services/okta-session.service';
import { NotifierService } from './services/notifier.service';

@NgModule({
  declarations: [
    LoginComponent,
    LoginProcessorComponent,
    LogoutDialogComponent,
    LogoutComponent,
    AuthPageLayoutComponent,
    Auth2PageLayoutComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    RouterModule,
    AppInputModule,
    AppButtonModule,
    AppDialogModule,
  ],
  providers: [OktaSessionService, NotifierService, AutoCompleteSearchPipe],
})
export class AuthModule {}
